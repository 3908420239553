import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
    {
        question: 'Ce fac in caz de accident?',
        answer: 'In cazul unui eveniment rutier , fie ca sunteti pagubit sau vinovat, nu este necesar decat sa ne contactati.Noi vom prelua cazul si ne vom ocupa pana la finalizarea acestuia.',
    },
    {
        question: 'Unde imi repar masina ?',
        answer: 'Autoturismele avariate vor fi reparate la colaboratorii nostri, ateliere de reparatie autorizate RAR.',
    },
    {
        question: 'Primesc masina la schimb?',
        answer: 'Da, soferii pagubiti vor beneficia de autoturism la schimb pe toata durata lipsei de folosinta a autovehicolului avariat.',
    },
    {
        question: ' Cine achita costul de inchiriere a masinii de schimb ?',
        answer: 'Costurile de inchiriere vor fi suportate de asigurator.',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const FAQs = () => (
    <div className="bg-slate-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto divide-y-2 divide-gray-200">
                <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
                    Intrebari frecvente
                </h2>
                <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                    {faqs.map((faq, idx) => (
                        <Disclosure as="div" key={idx} className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                            <span className="font-medium text-gray-900">
                                                {faq.question}
                                            </span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open
                                                            ? '-rotate-180'
                                                            : 'rotate-0',
                                                        'h-6 w-6 transform',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500">
                                            {faq.answer}
                                        </p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </dl>
            </div>
        </div>
    </div>
)
export default FAQs
